import styled from "styled-components";
import { FLEX_ROW } from "ui-kit";

export const OrganizationListWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(
    91deg,
    ${({ theme }) => theme.palette.primary.main} 100%,
    ${({ theme }) => theme.palette.primary.main} 100%
  );

  box-shadow: ${({ theme }) => theme.shadows[4]};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const OrganizationListImage = styled.img`
  width: 100%;
  height: 100%;
`;
