import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

import { formGroupClasses } from "@mui/material";

export const FormWrapper = styled.form`
  ${FLEX_COLUMN};
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const RadioButtonsWrapper = styled.div`
  width: 100%;
  min-width: 270px;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing()};

  .${formGroupClasses.root} {
    padding-left: ${({ theme }) => theme.spacing(3)};
    align-items: flex-start;
  }
`;
