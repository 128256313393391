import React from "react";

import { Grid } from "@mui/material";

export const TechSupportPage = () => {
  return (
    <Grid container spacing={5}>
      TechSupportPage
    </Grid>
  );
};
