import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_COLUMN_FULL } from "ui-kit";

export const Wrapper = styled.div`
  ${FLEX_COLUMN_FULL};
  min-width: 360px;
`;
export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)};

  button {
    min-width: ${UI_CONFIG.submitButton}px;
  }
  .send_to_do_button {
    min-width: ${UI_CONFIG.modalButton}px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  text-align: center;
  width: calc(100% - 80px);
  max-width: 500px;
  &.warning__message {
    width: calc(100% - 40px);
  }
`;
