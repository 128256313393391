import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN } from "ui-kit";

import { Card } from "@mui/material";

export const CardWrapper = styled(Card)`
  padding: ${({ theme }) => theme.spacing(4)};
  margin: ${({ theme }) => theme.spacing(4, 0)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  .MuiFormLabel-root {
    font-size: 12px !important;
  }
`;

export const CardTitleWrapper = styled.div`
  ${FLEX_BETWEEN};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  margin: ${({ theme }) => theme.spacing(4, 0, 3, 0)};
`;
