import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAppSelector } from "app";
import { Icon } from "ui-kit";

import {
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";
import { ITaskById, TypeOfTheTaskEnum } from "@types";

import generatedBySys from "../../../../../assets/backgrounds/generatedBySystem.png";
import { TaskDetailTextSection } from "../TaskDetailTextSection";
import { FeedbackRequestTaskDetail } from "./FeedbackRequestTaskDetail";
import { TaskDetailsHeader } from "./TaskDetailsHeader";

interface TaskDetailsProps {
  data: ITaskById;
  isInDrawer?: boolean;
}

export const TaskDetails: React.FC<TaskDetailsProps> = ({
  data,
  isInDrawer,
}) => {
  const {
    authorOfTheTask,
    executorOfTheTask,
    registration,
    typeOfTheTask,
    subjectOfTask,
    executionRequirements,
  } = data;

  const { t } = useTranslation("tasks");
  const { palette } = useTheme();
  const { userProfile } = useAppSelector((state) => state.auth);

  const isHighPriority = registration.priority;

  const isExecutor = userProfile?.personalData?.employees?.some(
    (i) => i.id === executorOfTheTask?.employee?.id
  );

  const isAuthor =
    userProfile?.personalData?.employees?.some(
      (i) => i.id === authorOfTheTask?.employee.id
    ) || false;

  const showSubject =
    typeOfTheTask === TypeOfTheTaskEnum.taskByTheDocument ||
    typeOfTheTask === TypeOfTheTaskEnum.resolutionForTheDocument;

  if (typeOfTheTask === TypeOfTheTaskEnum.supportTask)
    return <FeedbackRequestTaskDetail data={data} />;

  return (
    <Stack mt={6} gap={6}>
      {!isInDrawer && (
        <TaskDetailsHeader
          type={typeOfTheTask}
          isAuthor={isAuthor}
          status={data.registration.status}
          data={data}
          isExecutor={Boolean(isExecutor)}
        />
      )}

      <Grid container spacing={6}>
        <Grid item xs={isInDrawer ? 6 : 3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Tooltip
                arrow
                placement="top"
                hidden={true}
                title={
                  !authorOfTheTask?.employee ? (
                    t("generate_by_system")
                  ) : (
                    <div>
                      <p>
                        {
                          authorOfTheTask?.organization
                            .briefTextOrganizationName
                        }
                      </p>
                      <p>
                        {authorOfTheTask?.structuralUnit
                          ?.nameOfStructuralUnit || ""}
                      </p>
                    </div>
                  )
                }
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h9_semiBold" color="text.disabled">
                      {t("author.of.task")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {authorOfTheTask?.position ? (
                      <TaskDetailTextSection
                        label={t("position")}
                        content={authorOfTheTask?.position.namePosition}
                      />
                    ) : (
                      <Grid container xs={12}>
                        <Grid item xs={8} display="flex" alignItems="center">
                          <Typography variant="body1" fontWeight={600}>
                            {t("generate_by_system")}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <img
                            src={generatedBySys}
                            alt="generated By Sys."
                            width={112}
                            height={97}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {authorOfTheTask?.employee ? (
                    <Grid item xs={12}>
                      <TaskDetailTextSection
                        label={t("fullName.label")}
                        content={authorOfTheTask?.employee.fullName}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Tooltip>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={isInDrawer ? 6 : 3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Tooltip
                arrow
                placement="top"
                title={
                  <div>
                    <p>
                      {executorOfTheTask.organization.briefTextOrganizationName}
                    </p>
                    <p>
                      {executorOfTheTask.structuralUnit?.nameOfStructuralUnit ||
                        ""}
                    </p>
                  </div>
                }
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h9_semiBold" color="text.disabled">
                      {t("executor")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TaskDetailTextSection
                      label={t("position")}
                      content={executorOfTheTask.position.namePosition}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TaskDetailTextSection
                      label={t("fullName.label")}
                      content={executorOfTheTask.employee.fullName}
                    />
                  </Grid>
                </Grid>
              </Tooltip>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={isInDrawer ? 12 : 6}>
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h9_semiBold" color="text.disabled">
                    {t("registration")}
                  </Typography>
                </Grid>
                <Grid item xs={isInDrawer ? 6 : 3}>
                  <TaskDetailTextSection
                    label={t("task.number")}
                    content={registration.taskNumber}
                  />
                </Grid>
                <Grid item xs={isInDrawer ? 6 : 3}>
                  <TaskDetailTextSection
                    label={t("assignment.date")}
                    content={getLocaleDateFormat(registration.taskDate)}
                  />
                </Grid>
                <Grid item xs={isInDrawer ? 6 : 3}>
                  <TaskDetailTextSection
                    label={t("status")}
                    content={t(`statuses.${registration.status}`)}
                  />
                </Grid>
                <Grid item xs={isInDrawer ? 6 : 3}>
                  <TaskDetailTextSection
                    label={t("priority")}
                    content={
                      <Stack flexDirection="row" alignItems="center" gap={1.5}>
                        <Icon
                          name={isHighPriority ? "Triangle" : "Equal"}
                          size={20}
                          color={
                            isHighPriority
                              ? palette.error.main
                              : palette.success.main
                          }
                        />

                        <Typography variant="body2" fontWeight={600}>
                          {isHighPriority
                            ? t("priority.high")
                            : t("priority.low")}
                        </Typography>
                      </Stack>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TaskDetailTextSection
                    label={t("construction.complex.affiliation")}
                    content={
                      registration.constructionComplexAffiliation?.length >
                      0 ? (
                        registration.constructionComplexAffiliation.map((i) => (
                          <p key={i.id}>{i.briefTextConstructionComplexName}</p>
                        ))
                      ) : (
                        <Icon
                          name="Minus"
                          size={20}
                          color={palette.primary.main}
                        />
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        {showSubject && (
          <Grid item xs={isInDrawer ? 12 : 3}>
            <Card>
              <CardContent>
                <Typography variant="h9_semiBold" color="text.disabled">
                  {t("subject")}
                </Typography>

                <Typography
                  variant="body4"
                  color="text.secondary"
                  sx={{ display: "block", mt: 4 }}
                >
                  {t("document")}
                </Typography>
                {subjectOfTask?.correspondenceId ? (
                  <Link
                    to={`/documents/correspondence/${subjectOfTask.correspondenceId}`}
                  >
                    <Typography
                      variant="body2"
                      color="secondary"
                      fontWeight={600}
                      sx={{ display: "block", textDecoration: "underline" }}
                    >
                      {subjectOfTask.longNameOfTheDocument || ""}
                    </Typography>
                  </Link>
                ) : (
                  <Typography
                    variant="body2"
                    color="secondary"
                    fontWeight={600}
                    sx={{ display: "block", textDecoration: "underline" }}
                  >
                    {subjectOfTask?.longNameOfTheDocument || ""}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={isInDrawer ? 12 : showSubject ? 9 : 12}>
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h9_semiBold" color="text.disabled">
                    {t("execution.requirements")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TaskDetailTextSection
                    label={t("text")}
                    content={executionRequirements.textOfTheTask}
                    disableEllipsis
                  />
                </Grid>

                <Grid item xs={isInDrawer ? 4 : 2.4}>
                  <TaskDetailTextSection
                    label={t("control.date")}
                    content={getLocaleDateFormat(
                      executionRequirements.controlDate
                    )}
                  />
                </Grid>
                <Grid item xs={isInDrawer ? 4 : 2.4}>
                  <TaskDetailTextSection
                    label={t("number.working.days")}
                    content={executionRequirements.numberOfWorkingDays}
                  />
                </Grid>
                <Grid item xs={isInDrawer ? 4 : 2.4}>
                  <TaskDetailTextSection
                    label={t("number.calendar.days")}
                    content={executionRequirements.numberOfCalendarDays}
                  />
                </Grid>

                {!isExecutor && (
                  <Grid item xs={isInDrawer ? 4 : 2.4}>
                    <TaskDetailTextSection
                      label={t("personal.control")}
                      content={
                        <Icon
                          size={20}
                          color={
                            executionRequirements.personalControl
                              ? palette.success.main
                              : palette.primary.main
                          }
                          name={
                            executionRequirements.personalControl
                              ? "Check"
                              : "Minus"
                          }
                        />
                      }
                    />
                  </Grid>
                )}

                {typeOfTheTask !== TypeOfTheTaskEnum.selfAssignment && (
                  <Grid item xs={isInDrawer ? 4 : 2.4}>
                    <TaskDetailTextSection
                      label={t("report.required")}
                      content={
                        <Icon
                          size={20}
                          color={
                            executionRequirements.reportRequired
                              ? palette.success.main
                              : palette.primary.main
                          }
                          name={
                            executionRequirements.reportRequired
                              ? "Check"
                              : "Minus"
                          }
                        />
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};
