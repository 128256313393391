import { createSlice } from "@reduxjs/toolkit";
import { TasksTabEnum } from "@types";

interface ITask {
  taskCreationStep: number;
  currentTab: TasksTabEnum;
}

export const initialStateTask: ITask = {
  taskCreationStep: 0,
  currentTab: TasksTabEnum.incoming,
};

export const taskSlice = createSlice({
  name: "task",
  initialState: initialStateTask,
  reducers: {
    resetTaskCreationStep: (state) => {
      state.taskCreationStep = 0;
    },
    setChangeTaskCreationStep: (state, action) => {
      state.taskCreationStep = state.taskCreationStep + action.payload;
    },
    setTaskTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
});

export const { setChangeTaskCreationStep, resetTaskCreationStep, setTaskTab } =
  taskSlice.actions;

export default taskSlice.reducer;
