import React, { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AcceptedMimeTypes } from "app";
import { DragAndDropUploader } from "ui-kit";

import { Grid, Typography, useTheme } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { FormRadio } from "@sbm/ui-components";
import {
  FileAttachedToCorrespondenceFileStatusEnum,
  FileAttachedToCorrespondenceTypeOfAttachmentEnum,
} from "@types";

import { ATTACHMENT_DEFAULT_VALUES, IAttachmentForm } from "./constants";
import { LabelWrapper } from "./styles";

interface FormContentProps {
  onClose: () => void;
  file: File[] | null;
  onAddFile: (
    file: File[],
    typeOfAttachment: FileAttachedToCorrespondenceTypeOfAttachmentEnum,
    fileStatusAttachment: FileAttachedToCorrespondenceFileStatusEnum
  ) => void;
  isServiceNote?: boolean;
}

export const FormContent: React.FC<FormContentProps> = ({
  onAddFile,
  file,
  isServiceNote,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("attachments");

  const ref = React.useRef<HTMLFormElement | null>(null);

  const { control, setValue } = useForm<IAttachmentForm>({
    defaultValues: ATTACHMENT_DEFAULT_VALUES,
  });

  const { fileStatusAttachment, typeOfAttachment } = useWatch({
    control,
  });

  const isFileInputDisabled = !fileStatusAttachment || !typeOfAttachment;

  const assignmentOptions = Object.values(
    FileAttachedToCorrespondenceTypeOfAttachmentEnum
  )
    .filter((type) =>
      isServiceNote
        ? type !==
          FileAttachedToCorrespondenceTypeOfAttachmentEnum.deliveryConfirmation
        : type
    )
    .map((i) => {
      return {
        option: t(`correspondence_type_of_attachments.${i}`),
        value: i,
      };
    });

  const statusOptions = useMemo(() => {
    return [
      {
        option: t("document.signed"),
        value: FileAttachedToCorrespondenceFileStatusEnum.signedDocument,
      },
      {
        option: t("document.draft"),
        value: FileAttachedToCorrespondenceFileStatusEnum.draftDocument,
      },
    ];
  }, [t]);

  const handleFileSelect = (files: File[] | File) => {
    onAddFile(files as File[], typeOfAttachment!, fileStatusAttachment!);
  };

  useEffect(() => {
    setValue("file", null);
  }, [typeOfAttachment, fileStatusAttachment, setValue]);

  return (
    <div>
      <Typography
        component="p"
        variant="h7"
        fontWeight={700}
        color="primary.main"
        mb={4}
        mt={-6}
      >
        {t("add.file")}
      </Typography>

      <form noValidate ref={ref}>
        <Grid container spacing={4} sx={{ mb: 6 }}>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              fontWeight={700}
              color="primary.main"
              mb={3}
            >
              {t("drawer.purpose")}
            </Typography>

            <FormRadio
              name="typeOfAttachment"
              control={control}
              values={assignmentOptions}
              flexDirection="column"
              alignItems="flex-start"
              fullWidth={false}
              formControlLabelPropsSx={{ whiteSpace: "nowrap" }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              fontWeight={700}
              color="primary.main"
              mb={3}
            >
              {t("drawer.file_type")}
            </Typography>

            <FormRadio
              name="fileStatusAttachment"
              control={control}
              values={statusOptions}
              flexDirection="column"
              alignItems="flex-start"
              fullWidth={false}
              formControlLabelPropsSx={{ whiteSpace: "nowrap" }}
            />
          </Grid>
        </Grid>

        <DragAndDropUploader
          files={file}
          types={AcceptedMimeTypes}
          disabled={
            !fileStatusAttachment || !typeOfAttachment || Boolean(file?.length)
          }
          onChange={handleFileSelect}
          label={
            isFileInputDisabled ? (
              t("upload.disabled")
            ) : file ? (
              t("upload.done")
            ) : (
              <LabelWrapper>
                <Typography variant="subtitle1">
                  {t("upload.default")}
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={700}
                  sx={{ color: hexToRgba(theme.palette.primary.main, 0.7) }}
                >
                  {t("upload.or")}
                </Typography>
              </LabelWrapper>
            )
          }
        />
      </form>
    </div>
  );
};
