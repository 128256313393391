import React, { useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetDocumentLinks } from "api";
import { Modal, Table, useTableQueryMethods, useTableQuerySet } from "ui-kit";

import { Stack } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { getLocaleDateFormat } from "@sbm/fe-utils";
import { FormSelect } from "@sbm/ui-components";
import { IAttachLink, DocumentTypeOfTheDocumentEnum } from "@types";

import { useDebounce } from "../../../../hooks";
import { getAttachLinkTableColumns } from "./constant";

export const AttachLinkPopup = (props: {
  open: boolean;
  onClose: () => void;
  data: { setAttachList: (e: IAttachLink) => void };
}) => {
  const {
    open,
    onClose,
    data: { setAttachList },
  } = props;
  const { t } = useTranslation();

  const [search, setSearch] = useState("");

  const { control } = useForm<any>({
    defaultValues: {},
  });

  const fields = useWatch({ control });

  const { documentType } = fields;

  const debouncedSearch = useDebounce(search);

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetDocumentLinks(documentType, debouncedSearch);

  const handleAttachLink = (row: GridRowParams) => {
    setAttachList(row.row);
    onClose();
  };

  useTableQuerySet({
    search,
    setSearch,
  });

  const { onSearchChange } = useTableQueryMethods({
    setSearch,
  });

  const handleRowClick = (row: GridRowParams) => {
    handleAttachLink(row);
  };

  const documentTypesArray = Object.keys(DocumentTypeOfTheDocumentEnum).map(
    (key) => ({
      option: t(
        DocumentTypeOfTheDocumentEnum[
          key as keyof typeof DocumentTypeOfTheDocumentEnum
        ]
      ),
      value:
        DocumentTypeOfTheDocumentEnum[
          key as keyof typeof DocumentTypeOfTheDocumentEnum
        ],
    })
  );

  const rows: IAttachLink[] = useMemo(
    () =>
      data?.pages?.flatMap((page) => {
        return page.items.map((item, index) => {
          return {
            ...item,
            sequenceNumber:
              (page.meta.currentPage - 1) * page.meta.itemsPerPage + index + 1,
            longNameOfTheDocument: item.longNameOfTheDocument,
            createdAt: getLocaleDateFormat(item.createdAt),
            context: item.contextOfTheDocument,
          };
        });
      }) || [],
    [data]
  );

  const totalItems = data?.pages[0].meta.totalItems ?? 0;

  const columns: GridColDef[] = useMemo(
    () => getAttachLinkTableColumns(t),
    [t]
  );

  const content = {
    title: t("messages.attach_link"),
    body: (
      <>
        <Stack width={documentType ? 600 : "100%"}>
          <FormSelect
            label={t("messages.document_type")}
            name="documentType"
            size="medium"
            variant="outlined"
            required
            values={documentTypesArray}
            control={control}
          />
        </Stack>

        {documentType ? (
          <Table
            rows={rows}
            columns={columns}
            rowCount={totalItems}
            loading={isLoading || isFetchingNextPage}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            onSearchOptionChange={onSearchChange}
            onRowClick={handleRowClick}
            hasToolbar
            hideFooter
          />
        ) : null}
      </>
    ),
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      content={content}
      sizeLarge={documentType}
      updatedVersion
    />
  );
};
