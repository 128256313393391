import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import * as d3 from "d3";
import { OrgChart } from "d3-org-chart";
import { Icon } from "ui-kit";

import { Stack, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { TreeItem2Props } from "@mui/x-tree-view";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { hexToRgba, isIOS } from "@sbm/fe-utils";
import { getFormattedTreeData, TreeNode } from "@sbm/fe-utils";
import { EnumOrganizationViewMode } from "@types";

import AssistantDirector from "../../../../assets/backgrounds/assistant_director.svg";
import Director from "../../../../assets/backgrounds/director.svg";
import Avatar from "../../../../assets/images/avatars/male_avatar.png";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  useMedia,
} from "../../../hooks";
import { setStaffAllocation } from "../../../store";
import { addExpandedProperty } from "./helpers";
import { StyledTreeItem, StyledToggleButton } from "./styles";

const CustomTreeItem = React.forwardRef(
  (props: TreeItem2Props, ref: React.Ref<HTMLLIElement>) => {
    return (
      <StyledTreeItem
        ref={ref}
        {...props}
        slotProps={{
          label: {
            className:
              props.itemId && String(props.itemId).includes("head-unit")
                ? "font-bold"
                : "",
          },
        }}
      />
    );
  }
);

interface OrgChartData {
  id: number;
  name?: string;
  position?: string;
  nameOfStructuralUnit?: string;
  parentId?: number | string;
  isDirector?: boolean;
  isAssistantDirector?: boolean;
}

interface OrganizationTreeContainerProps {
  orgName: string;
  data?: OrgChartData[];
  onNodeClick: (nodeId: unknown) => void;
}

const DEFAULT_VIEW_MODE = EnumOrganizationViewMode.cardMode;

export const OrganizationTreeContainer: React.FC<
  OrganizationTreeContainerProps
> = (props) => {
  const { data, orgName, onNodeClick } = props;
  const iosDetected = isIOS();
  const [{ tabletDown }] = useMedia();

  const isIosMobileOrTablet = iosDetected && tabletDown;

  const [mode, setMode] = useState<EnumOrganizationViewMode | null>(
    DEFAULT_VIEW_MODE
  );
  const d3Container = useRef(null);
  const chart = useRef<OrgChart<OrgChartData> | null>(null);

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { staffAllocation } = useAppSelector((state) => state.organizations);

  const tree = (staffAllocation?.nodes || []) as TreeViewBaseItem[];

  const formattedTreeData = getFormattedTreeData(
    staffAllocation as unknown as { nodes: TreeNode[] }
  );

  const ids =
    formattedTreeData?.map((i) => {
      if (i.staffUnit?.headOfStructuralUnit) return `head-unit__${i.id}`;

      return String(i.id);
    }) || [];

  useEffectOnce(() => {
    return () => {
      dispatch(setStaffAllocation(null));
    };
  });

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (!chart.current) {
      chart.current = new OrgChart();
    }

    if (data && d3Container.current) {
      chart.current
        .container(d3Container.current)
        .data(data)
        // .onNodeClick((node: unknown) => {
        //   console.log(node, "Id of clicked node ");
        //   onNodeClick(node);
        // })
        .nodeHeight(() => 225 + 45)
        .nodeWidth(() => 178 + 2)
        .childrenMargin(() => 100)
        .compactMarginBetween(() => 35)
        .compactMarginPair(() => 30)
        .neighbourMargin(() => 20)
        .compact(false)
        .linkUpdate(function (this: SVGPathElement) {
          d3.select(this)
            .attr("stroke", () => theme.palette.secondary.main)
            .attr("stroke-width", () => 1)
            .attr("stroke-dasharray", () => "2");
        })
        .nodeContent(
          (node: { data: OrgChartData; width?: number; height: number }) => {
            const color = "#FFFFFF";
            const imageDiffVert = 10 + 2;
            const isDirector = node.data.isDirector;
            const isAssistant = node.data.isAssistantDirector;

            const renderIcon = () => {
              if (isDirector) {
                return `<div style="width: 100%; display: flex; justify-content:center;">
                  <img src="${Director}" />
                </div>`;
              } else if (isAssistant) {
                return `<div style="width: 100%; display: flex; justify-content:center;">
                  <img src="${AssistantDirector}" />
                </div>`;
              } else {
                return "";
              }
            };

            return `
              <div style="width:${node?.width}px;height:${
              node.height
            }px;padding-top:${
              imageDiffVert + 5
            }px;padding-left:1px;padding-right:1px">
                <div style="background-color:${color};width:${
              node?.width
            }px;height:${node.height}px;border-radius:16px; box-shadow: ${
              isIosMobileOrTablet ? "none" : theme.shadows[6]
            }; font-family:'Montserrat'">
                  <div style="display: flex; background: ${hexToRgba(
                    theme.palette.primary.light,
                    0.04
                  )}; gap:3px; justify-content:center; display:${
              isDirector || isAssistant ? "none" : "flex"
            }; border-top-left-radius: 16px; border-top-right-radius: 16px;  padding: 10px 6px 0px 6px; width: 100%; height: 78px;align-items: center;">
                    <p style="font-weight: 700; font-size: 14px; line-height:${
                      isDirector || isAssistant ? "19.6px" : "normal"
                    };color: #3B608C; text-align:center;">${
              node.data.nameOfStructuralUnit
            }</p>
                  </div>
                  <div style="display:flex; justify-content:center; align-items:center; width: 100%;padding-top: ${
                    isDirector || isAssistant ? "16px" : "8px"
                  };">
                    <img src="${Avatar}" style="border-radius:25px;width:50px;height:50px;" />
                  </div>
                  <div style="display:flex; flex-direction: column;  align-items: center; gap: 4px; width: 100%;height:35%; top: ${
                    isDirector || isAssistant ? "76px" : "144px"
                  };padding: 8px 6px;">
                    <p style="font-weight: 700;font-size:14px;color:${
                      theme.palette.primary
                    }; line-height: 19.6px; text-align: center;">${
              node.data.name
            }</p>
                    <p style="font-weight: 600;font-size:10px;color:${
                      theme.palette.text.secondary
                    }; line-height: 14px; text-align: center;">${
              node.data.position
            }</p>
                  </div>
                  ${renderIcon()}
                </div>
              </div>`;
          }
        )
        .render();
    }

    return () => {
      chart.current = null;
    };
  }, [data, onNodeClick, theme, mode, isIosMobileOrTablet]);

  if (!tree) return null;

  const treeData = tree.map((node) =>
    addExpandedProperty(node, t)
  ) as TreeViewBaseItem[];

  const handleMode = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: EnumOrganizationViewMode | null
  ) => {
    if (!newAlignment) {
      return;
    }
    setMode(newAlignment);
  };

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        justifyContent="space-between"
        marginTop={theme.spacing(5)}
      >
        <Typography
          variant="h6"
          fontWeight={600}
          color={theme.palette.text.secondary}
        >
          {orgName}
        </Typography>
        <ToggleButtonGroup
          color="secondary"
          size="small"
          value={mode}
          exclusive
          onChange={handleMode}
        >
          <StyledToggleButton value={EnumOrganizationViewMode.cardMode}>
            <Icon
              name="Network"
              className="cursor-pointer"
              color={
                mode === EnumOrganizationViewMode.cardMode
                  ? theme.palette.secondary.main
                  : hexToRgba(theme.palette.primary.main, 0.5)
              }
            />
          </StyledToggleButton>
          <StyledToggleButton value={EnumOrganizationViewMode.lineMode}>
            <Icon
              name="ListTree"
              className="cursor-pointer"
              color={
                mode === EnumOrganizationViewMode.lineMode
                  ? theme.palette.secondary.main
                  : hexToRgba(theme.palette.primary.main, 0.5)
              }
            />
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {mode === EnumOrganizationViewMode.lineMode ? (
        <Stack mt={6}>
          <Stack>
            <Typography variant="h5" fontWeight={700}>
              {t("organizational.structure")}
            </Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {orgName}
            </Typography>
          </Stack>

          <Stack sx={{ mt: 6, overflowX: "auto" }}>
            <RichTreeView
              defaultExpandedItems={ids}
              items={treeData}
              slots={{ item: CustomTreeItem }}
            />
          </Stack>
        </Stack>
      ) : (
        <div ref={d3Container} />
      )}
    </Stack>
  );
};
