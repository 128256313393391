import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  height: 88px;
  background: ${({ theme }) => theme.palette.background.default};
  width: 100%;
`;
export const AttachmentIconsWrapper = styled.div`
  ${FLEX_ROW_FULL};
  height: 100%;
  gap: ${({ theme }) => theme.spacing(2)};
  .attach_indicator {
    height: ${({ theme }) => theme.spacing(2)};
    width: ${({ theme }) => theme.spacing(2)};
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.palette.background.default};
    background: ${({ theme }) => theme.palette.secondary.main};
  }
`;
export const SendTextWrapper = styled.div`
  ${FLEX_ROW_FULL};
  height: 100%;
`;

export const SendIconWrapper = styled.div`
  ${FLEX_ROW_FULL};
  height: 100%;
  justify-content: start;
  margin: ${({ theme }) => theme.spacing(0, 1)};
  cursor: pointer;
  .sending__icon {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    ${FLEX_ROW_FULL};
    :hover {
      background: ${({ theme }) =>
        hexToRgba(theme.palette.secondary.main, 0.04)};
    }
    :active {
      background: ${({ theme }) =>
        hexToRgba(theme.palette.secondary.main, 0.1)};
    }
  }
`;
