import React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";

import { Icon } from "ui-kit";

import {
  Collapse,
  Fade,
  List,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

import { TSidebarItem } from "../../constants";

interface SidebarCollapseProps {
  openCollapse: string;
  onClick: (route: string, collapse?: boolean | undefined) => void;
  collapse?: TSidebarItem[];
  open: boolean;
}

export const SidebarCollapse: React.FC<SidebarCollapseProps> = ({
  onClick,
  openCollapse,
  collapse,
  open,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const { pathname } = useLocation();

  const getColor = (active: boolean) =>
    active
      ? theme.palette.secondary.light
      : hexToRgba(theme.palette.primary.contrastText, 0.7);

  return (
    <Fade in timeout={500}>
      <Collapse in={Boolean(openCollapse)} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            display: "block",
            pt: 2,
            background: theme.palette.primary.dark,
            borderRadius: 2,
          }}
        >
          {collapse?.map((subItem) => {
            const { name, icon, route } = subItem;

            const isActive =
              Boolean(matchPath(route, pathname)) ||
              (route !== "/" && pathname.includes(route));

            const toggledColor = getColor(isActive);

            return (
              <ListItemButton
                key={name}
                onClick={() => onClick(route)}
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  py: 4,
                  px: 2,
                  gap: 4,
                  borderRadius: 2,
                  "&:hover": {
                    borderRadius: 0,
                    backgroundColor: hexToRgba(
                      theme.palette.primary.contrastText,
                      0.04
                    ),
                  },
                }}
              >
                <Icon name={icon} color={toggledColor} />
                {open && (
                  <Fade in timeout={500}>
                    <Typography
                      variant="body2"
                      fontWeight={isActive ? 600 : 400}
                      color={toggledColor}
                      sx={{ flex: 1 }}
                    >
                      {t(name)}
                    </Typography>
                  </Fade>
                )}
                {isActive && open && (
                  <Icon
                    name="Dot"
                    strokeWidth={8}
                    color={theme.palette.secondary.light}
                  />
                )}
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </Fade>
  );
};
