import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const ContentWrapper = styled.div`
  ${FLEX_BETWEEN};
  flex-direction: row;
  width: 100%;
`;

export const TableFiltersWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  width: 100%;
`;

export const GridToolbarWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: end;
  width: 100%;
`;
