import { MouseEvent } from "react";

import * as icons from "lucide-react";

import { useTheme } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { TIconNames } from "@types";

interface IconProps {
  name: TIconNames;
  color?: string;
  size?: number;
  strokeWidth?: number;
  fill?: string;
  className?: string;
  disabled?: boolean;
  onClick?: (e?: MouseEvent<SVGSVGElement>) => void;
}

export const Icon = (props: IconProps) => {
  const theme = useTheme();

  const {
    name,
    color = theme.palette.primary.contrastText,
    size = 24,
    fill = "none",
    disabled = false,
    ...rest
  } = props;

  const LucideIcon = icons[name] as icons.LucideIcon;

  return (
    <>
      {name === "MessageCircle" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-message-square-text"
        >
          <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
          <path d="M13 8H7" />
          <path d="M17 12H7" />
        </svg>
      ) : (
        <LucideIcon
          color={disabled ? hexToRgba(color, 0.26) : color}
          size={size}
          style={{
            fill,
            cursor:
              rest.onClick || rest.className === "cursor-pointer"
                ? "pointer"
                : "initial",
          }}
          {...rest}
        />
      )}
    </>
  );
};
