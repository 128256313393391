import React from "react";

const typography = {
  h1: {
    fontSize: "92px",
    lineHeight: "117.02px",
    letterSpacing: "-1.5px",
    fontWeight: 300,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  h2: {
    fontSize: "60px",
    lineHeight: "73.14px",
    letterSpacing: "-0.5px",
    fontWeight: 300,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  h3: {
    fontSize: "48px",
    lineHeight: "58.51px",
    fontWeight: 300,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  h4: {
    fontSize: "34px",
    lineHeight: "41.45px",
    letterSpacing: "0.25px",
    fontWeight: 400,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  h5: {
    fontSize: "28px",
    lineHeight: "34.13px",
    letterSpacing: "0.15px",
    fontWeight: 400,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  h6: {
    fontSize: "24px",
    lineHeight: "29.26px",
    letterSpacing: "0.15px",
    fontWeight: 600,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  h6_semiBold: {
    fontSize: "24px",
    lineHeight: "29.26px",
    letterSpacing: "0.15px",
    fontWeight: 600,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  h7: {
    fontSize: "20px",
    lineHeight: "24.38px",
    letterSpacing: "0.15px",
    fontWeight: 400,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  h8: {
    fontSize: "18px",
    lineHeight: "21.94px",
    letterSpacing: "0.15px",
    fontWeight: 600,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  h8_bold: {
    fontSize: "18px",
    lineHeight: "21.94px",
    letterSpacing: "0.15px",
    fontWeight: 700,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  h9_semiBold: {
    fontSize: "14px",
    lineHeight: "17.07px",
    letterSpacing: "0.15px",
    fontWeight: 600,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  subtitle1: {
    fontSize: "16px",
    lineHeight: "28px",
    letterSpacing: "0.15px",
    fontWeight: 400,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  subtitle2: {
    fontSize: "14px",
    lineHeight: "22.4px",
    letterSpacing: "0.1px",
    fontWeight: 500,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  caption: {
    fontSize: "12px",
    lineHeight: "19.2px",
    letterSpacing: "0.4px",
    fontWeight: 400,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  overline: {
    fontSize: "12px",
    lineHeight: "31.2px",
    letterSpacing: "1px",
    fontWeight: 400,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  body1: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15",
    fontWeight: 400,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  body2: {
    fontSize: "14px",
    lineHeight: "21px",
    letterSpacing: "0.15px",
    fontWeight: 400,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  body3: {
    fontSize: "12px",
    lineHeight: "16.8px",
    letterSpacing: "0.15px",
    fontWeight: 400,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
  body4: {
    fontSize: "10px",
    lineHeight: "14px",
    letterSpacing: "0.15px",
    fontWeight: 400,
    userSelect: "text" as React.CSSProperties["userSelect"],
  },
};

export default typography;
