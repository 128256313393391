import toast from "react-hot-toast";

import {
  fetchPermissionsList,
  fetchPermissionsSetById,
  fetchPermissionsSets,
  fetchServices,
  fetchRoles,
  fetchRoleById,
  editPermissionsSetMutation,
  fetchPermissionsSetList,
  createRoleMutation,
  fetchAssignedRoleById,
  deletePermissionSet,
  editRoleMutation,
  fetchUsersByRoleId,
  deleteRole,
  createRoleForUserMutation,
  deleteRoleForUser,
  editRoleForUserMutation,
  fetchCHeckPermissionForTask,
} from "api";
import { createPermissionsSetMutation, getResourceTypes } from "api";
import { AxiosError } from "axios";

import { getAPIErrorMessage, getFiltersQueryCacheKey } from "@sbm/fe-utils";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  IAssignedRole,
  IAssignedRoleForm,
  IMeta,
  IPermission,
  IPermissionListForRole,
  IPermissionSet,
  IPermissionSetById,
  IRole,
  IRoleForm,
  IRoleList,
  IService,
  PermissionNameEnum,
} from "@types";
import { IPermissionSetForm, IResourcesType } from "@types";

export const useGetPermissionsList = ({
  filter,
  search,
  sortBy = "",
  isServicesFetched,
  limit = 20,
}: {
  filter: string[];
  search?: string;
  sortBy: string;
  isServicesFetched: boolean;
  limit?: number;
}) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useInfiniteQuery<{ items: IPermission[]; meta: IMeta }>(
    ["get_user_permission_list", search, ...queryKeyFilter, sortBy],
    (params) => fetchPermissionsList(params, filter, sortBy, search, limit),
    {
      enabled: isServicesFetched,
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetPermissionsSets = ({
  filter,
  search,
  isServicesFetched,
}: {
  filter: string[];
  search?: string;
  isServicesFetched: boolean;
}) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  const query = useInfiniteQuery<{ items: IPermissionSet[]; meta: IMeta }>(
    ["get_user_permission_sets", search, ...queryKeyFilter],
    (params) => fetchPermissionsSets(params, filter, search),
    {
      enabled: isServicesFetched,
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
  return query;
};

export const useGetRolesList = ({
  filter,
  search,
  sortBy,
  userId,
}: {
  filter: string[];
  search: string;
  sortBy: string;
  userId?: number | undefined;
}) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useInfiniteQuery<{ items: IRoleList[]; meta: IMeta }>(
    ["get_user_permission_sets", search, ...queryKeyFilter, sortBy],
    (params) => fetchRoles(params, filter, search, sortBy, userId),
    {
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetPermissionsSetsList = (id: number) => {
  return useQuery<IPermissionListForRole[] | undefined>(
    ["get_permission_set_list", id],
    async () => await fetchPermissionsSetList(id)
  );
};

export const useGetPermissionsSetById = (id: number) => {
  return useQuery<IPermissionSetById | undefined>(
    ["get_permission_set_by_id", id],
    async () => await fetchPermissionsSetById(id)
  );
};

export const useGetRoleById = (id: number, userId?: number) => {
  const enabled = !!id;

  return useQuery<IRole | undefined>(
    ["get_role_by_id", id, userId],
    async () => await fetchRoleById(id, userId),
    {
      enabled, // Enable the query only when roleId is defined
    }
  );
};

export const useGetServices = ({
  page,
  limit,
  search,
}: {
  page: number;
  limit: number;
  search?: string;
}) => {
  return useQuery<{ items: IService[]; meta: { totalItems: number } }>(
    ["get_services", page, limit],
    async () => {
      return await fetchServices({ page, limit });
    },
    {
      retry: false,
      keepPreviousData: true,
      initialData: { items: [], meta: { totalItems: 0 } },
      onError: (err) => {
        const errorMSG = getAPIErrorMessage(err as AxiosError);
        if (errorMSG) {
          toast.error(errorMSG);
        }
      },
    }
  );
};

export const useCreateRole = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: IRoleForm) => {
    const result = await createRoleMutation(data);
    await queryClient.refetchQueries(["get_user_permission_sets"]);
    return result;
  });
};
export const useEditRole = () => {
  const queryClient = useQueryClient();

  return useMutation(async ({ id, data }: { id: number; data: IRoleForm }) => {
    const result = await editRoleMutation(id, data);
    await queryClient.refetchQueries(["get_user_permission_sets"]);
    await queryClient.refetchQueries(["get_role_by_id", id]);
    return result;
  });
};

export const useGetUsersByRoleId = (id: number) => {
  const enabled = !!id;

  return useQuery<{ id: number; name: string }[] | undefined>(
    ["get_users_by_role_id", id],
    async () => await fetchUsersByRoleId(id),
    {
      enabled,
    }
  );
};

export const useCheckPermissionsForReqTask = () => {
  return useQuery<
    | {
        hasPermission: boolean;
        permissionName: PermissionNameEnum;
      }[]
    | undefined
  >(["get_users_by_role_id"], async () => await fetchCHeckPermissionForTask());
};

export const useCreatePermissionsSet = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: IPermissionSetForm) => {
    const result = await createPermissionsSetMutation(data);
    await queryClient.refetchQueries(["get_user_permission_sets"]);
    return result;
  });
};
export const useCreateRoleForUser = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: IAssignedRoleForm) => {
    const result = await createRoleForUserMutation(data);
    await queryClient.refetchQueries(["get_user_roles"]);
    return result;
  });
};

export const useEditRoleForUser = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(async (data: IAssignedRoleForm) => {
    const result = await editRoleForUserMutation(data, id);
    await queryClient.refetchQueries(["get_user_roles"]);
    return result;
  });
};

export const useDeleteUserRole = (id?: number) => {
  const queryClient = useQueryClient();

  return useMutation(async () => {
    const result = await deleteRoleForUser(id as number);
    await queryClient.refetchQueries(["get_user_roles"]);
    return result;
  });
};

export const useDeletePermissionSet = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(async () => {
    const result = await deletePermissionSet(id);
    await queryClient.refetchQueries(["get_user_permission_sets"]);
    return result;
  });
};

export const useDeleteRole = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(async () => {
    const result = await deleteRole(id);
    await queryClient.refetchQueries(["get_user_permission_sets"]);
    return result;
  });
};

export const useEditPermissionsSet = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, data }: { id: number; data: IPermissionSetForm }) => {
      const result = await editPermissionsSetMutation(id, data);

      await queryClient.refetchQueries(["get_user_permission_sets"]);
      await queryClient.refetchQueries(["get_permission_set_by_id", id]);

      return result;
    }
  );
};

export const useGetAssignedRoles = (id: number | undefined) => {
  const enabled = !!id;
  return useQuery<IAssignedRole[] | undefined>(
    ["get_assigned_role_by_id", id],
    async () => await fetchAssignedRoleById(id as number),
    {
      enabled,
    }
  );
};

export const useGetResourceTypes = ({
  serviceID,
}: {
  serviceID?: number | undefined;
}) => {
  return useQuery<{ items: IResourcesType[]; meta: IMeta }>(
    ["get_resource_types", serviceID || ""],
    async () => {
      if (!serviceID) {
        return { items: [], meta: {} };
      }
      return await getResourceTypes(serviceID);
    },
    {
      retry: false,
      keepPreviousData: true,
      onError: (err) => {
        const errorMSG = getAPIErrorMessage(err as AxiosError);
        if (errorMSG) {
          toast.error(errorMSG);
        }
      },
    }
  );
};
