import { createTheme } from "@mui/material/styles";

import { components } from "./components";
import palette from "./palette";
import typography from "./typography";

export let MuiTheme = createTheme({
  palette,
  spacing: 4,
  shape: { borderRadius: 4 },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 16,
    htmlFontSize: 16,
    ...typography,
  },
});

MuiTheme = createTheme(MuiTheme, {
  components: {
    ...components(MuiTheme),
    MuiTypography: {
      variants: [
        {
          props: { variant: "h6_semiBold" },
          style: typography.h6_semiBold,
        },
        {
          props: { variant: "h7" },
          style: typography.h7,
        },
        {
          props: { variant: "h8" },
          style: typography.h8,
        },
        {
          props: { variant: "h8_bold" },
          style: typography.h8_bold,
        },
        {
          props: { variant: "h9_semiBold" },
          style: typography.h9_semiBold,
        },
        {
          props: { variant: "body3" },
          style: typography.body3,
        },
        {
          props: { variant: "body4" },
          style: typography.body4,
        },
      ],
    },
  },
});

export default MuiTheme;
