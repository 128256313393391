import toast from "react-hot-toast";

import { axiosService } from "app";
import { AxiosError } from "axios";

import { getAPIErrorMessage, getFilterParams } from "@sbm/fe-utils";
import { QueryFunctionContext } from "@tanstack/react-query";
import { IAssignedRoleForm, IPermissionSetForm, IRoleForm } from "@types";

export const fetchPermissionsList = async (
  { pageParam }: QueryFunctionContext,
  filter: (string | undefined)[] = [],
  sortBy = "",
  search = "",
  limit = 20
) => {
  const page = pageParam ?? 1;

  const dateFilters = ["filter.createdAt"];
  const filterParams = getFilterParams(filter, dateFilters);

  const params = {
    search,
    sortBy,
    limit,
    page,
    ...filterParams,
  };

  const { data } = await axiosService({
    endpoint: "permissions",
    body: params,
  });

  return data;
};

export const fetchPermissionsSets = async (
  { pageParam }: QueryFunctionContext,
  filter: (string | undefined)[] = [],
  search = ""
) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const dateFilters = ["filter.createdAt"];
  const filterParams = getFilterParams(filter, dateFilters);

  const params = {
    search,
    limit,
    page,
    ...filterParams,
  };

  const { data } = await axiosService({
    endpoint: "set-of-permissions",
    body: params,
  });

  return data;
};

export const fetchRoles = async (
  { pageParam }: QueryFunctionContext,
  filter: (string | undefined)[] = [],
  search = "",
  sortBy = "",
  userId: number | undefined
) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const dateFilters = ["filter.createdAt"];
  const filterParams = getFilterParams(filter, dateFilters);

  const params = {
    search,
    limit,
    page,
    sortBy,
    userId,
    ...filterParams,
  };

  const { data } = await axiosService({
    endpoint: "roles",
    body: params,
  });

  return data;
};

export const fetchPermissionsSetById = async (id: number) => {
  if (!id) return;

  const { data } = await axiosService({
    endpoint: `set-of-permissions/${id}`,
  });

  return data;
};

export const fetchPermissionsSetList = async (id: number) => {
  if (!id) return;

  const { data } = await axiosService({
    endpoint: `set-of-permissions/list?serviceId=${id}`,
  });

  return data;
};

export const fetchRoleById = async (id: number, userId?: number) => {
  const { data } = await axiosService({
    endpoint: userId
      ? `role-for-user/role/${id}/user/${userId}`
      : `roles/${id}`,
  });

  return data;
};

export const fetchRoleForUserId = async (roleId: number, userId: number) => {
  const { data } = await axiosService({
    endpoint: `role-for-user/role/${roleId}/user/${userId}`,
  });

  return data;
};

export const fetchServices = async ({
  page,
  limit,
}: {
  page: number;
  limit: number;
}) => {
  const params = {
    limit,
    page,
  };

  const { data } = await axiosService({
    endpoint: `services`,
    body: params,
  });

  return data;
};

export const createPermissionsSetMutation = async (
  body: IPermissionSetForm
) => {
  try {
    const response = await axiosService({
      endpoint: "/set-of-permissions",
      method: "POST",
      body,
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};

export const createRoleForUserMutation = async (body: IAssignedRoleForm) => {
  try {
    const response = await axiosService({
      endpoint: "/role-for-user",
      method: "POST",
      body,
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};

export const editRoleForUserMutation = async (
  body: IAssignedRoleForm,
  id: number
) => {
  try {
    const response = await axiosService({
      endpoint: `/role-for-user/${id}`,
      method: "PUT",
      body,
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};

export const deleteRoleForUser = async (id: number) => {
  if (!id) return;

  try {
    const response = await axiosService({
      endpoint: `/role-for-user/${id}`,
      method: "DELETE",
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};

export const createRoleMutation = async (body: IRoleForm) => {
  try {
    const response = await axiosService({
      endpoint: "/roles",
      method: "POST",
      body,
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};

export const editRoleMutation = async (id: number, data: IRoleForm) => {
  try {
    const response = await axiosService({
      endpoint: `/roles/${id}`,
      method: "PUT",
      body: data,
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};

export const fetchUsersByRoleId = async (id: number) => {
  const { data } = await axiosService({
    endpoint: `role-for-user/role/${id}/users`,
  });

  return data;
};

export const fetchCHeckPermissionForTask = async () => {
  const { data } = await axiosService({
    endpoint: `/permissions/check`,
    method: "POST",
    body: [
      {
        permissionName: "createSupportTask",
      },
    ],
  });

  return data;
};

export const deletePermissionSet = async (id: number) => {
  try {
    const response = await axiosService({
      endpoint: `/set-of-permissions/${id}`,
      method: "DELETE",
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};

export const deleteRole = async (id: number) => {
  try {
    const response = await axiosService({
      endpoint: `/roles/${id}`,
      method: "DELETE",
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};

export const editPermissionsSetMutation = async (
  id: number,
  data: IPermissionSetForm
) => {
  try {
    const response = await axiosService({
      endpoint: `/set-of-permissions/${id}`,
      method: "PUT",
      body: data,
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};

export const fetchAssignedRoleById = async (id: number) => {
  try {
    const response = await axiosService({
      endpoint: `/set-of-permissions-for-role/getRolesByPermissionSetId/${id}`,
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};

export const getResourceTypes = async (serviceID?: number | undefined) => {
  const params: { "filter.serviceId"?: number } = {};
  if (serviceID) {
    params["filter.serviceId"] = serviceID;
  }
  const { data } = await axiosService({
    endpoint: `resource-for-services`,
    body: params,
  });

  return data;
};
