import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER, FLEX_ROW_FULL } from "ui-kit";

export const TableWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const TaskViewingIndicatorWrapper = styled.div`
  ${FLEX_ROW_FULL};
  gap: ${({ theme }) => theme.spacing()};
  overflow: visible !important;
`;

export const CellWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  gap: ${({ theme }) => theme.spacing()};
  margin-bottom: 8px;
  font-weight: 400;
`;
