import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AcceptedMimeTypes, setModal, useAppDispatch } from "app";
import { Icon, MessengerDragAndDrop } from "ui-kit";

import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { IAttachLink, ICreateFeedbackRequest, ModalVariants } from "@types";

import { AttachedFileForRequest } from "../AttachedFile/AttachedFileForRequest";
import { AttachedLinkForRequest } from "../AttachedLink/AttachLinkForRequest";
import { FILES_MAX_COUNT, FILES_MAX_SIZE, LINKS_MAX_COUNT } from "../constants";
import { CardTitleWrapper, CardWrapper } from "../styles";

interface Props {
  form: UseFormReturn<ICreateFeedbackRequest>;
}

export const AttachmentsForFeedback = ({
  form: { control, setValue, getValues },
}: Props) => {
  const { t } = useTranslation("tasks");
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { files, links = [] } = useWatch<ICreateFeedbackRequest>({
    control,
  });

  const addAttachLink = (link: IAttachLink) => {
    const newList = getValues("links") || [];
    newList.push(link);
    setValue("links", newList);
  };

  const handleAttachLink = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.attachLink,
        content: { setAttachList: addAttachLink },
      })
    );
  };

  const handleFileSelect = (files: File | File[]) => {
    setValue("files", files as File[]);
  };

  return (
    <CardWrapper>
      <CardTitleWrapper>
        <Typography variant="h9_semiBold" color="text.disabled">
          {t("append")}
        </Typography>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={2}
        >
          <IconButton
            size="small"
            sx={{
              borderRadius: 2,
              gap: 1,
              opacity:
                Number(getValues("links")?.length) >= LINKS_MAX_COUNT ? 0.5 : 1,
            }}
            onClick={handleAttachLink}
            disabled={Number(getValues("links")?.length) >= LINKS_MAX_COUNT}
          >
            <Icon size={18} name="Link" color={theme.palette.secondary.main} />
            <Typography fontSize={13} fontWeight={600} color="secondary.main">
              {t("add.link")}
            </Typography>
          </IconButton>
          <IconButton
            size="small"
            sx={{
              borderRadius: 2,
              gap: 1,
              opacity: !files ? 1 : files.length < FILES_MAX_COUNT ? 1 : 0.5,
            }}
          >
            <MessengerDragAndDrop
              files={files}
              buttonOnly
              types={AcceptedMimeTypes}
              disabled={files ? FILES_MAX_COUNT <= files.length : false}
              maxFiles={FILES_MAX_COUNT}
              maxSize={FILES_MAX_SIZE}
              multiple
              onChange={handleFileSelect}
              customizeButton
            />
          </IconButton>
        </Stack>
      </CardTitleWrapper>
      <AttachedLinkForRequest control={control} setValue={setValue} />
      <AttachedFileForRequest control={control} setValue={setValue} />
    </CardWrapper>
  );
};
