import React, { useMemo } from "react";
import { EventProps } from "react-big-calendar";
import { useTranslation } from "react-i18next";

import {
  getTaskStatusChipConfig,
  openDrawer,
  useAppDispatch,
  useQueryParams,
} from "app";
import { Icon } from "ui-kit";

import { Chip, Stack, Typography, useTheme } from "@mui/material";
import {
  DrawerVariantsEnum,
  StatusForAuthorEnum,
  StatusForExecutorEnum,
  TasksTabEnum,
} from "@types";

const COMPLETED_TYPES_EXECUTOR = [
  StatusForExecutorEnum.done,
  StatusForExecutorEnum.doneInPerson,
  StatusForExecutorEnum.canceled,
  StatusForExecutorEnum.executionCompleted,
  StatusForExecutorEnum.resolutionExecuted,
];

const COMPLETED_TYPES_AUTHOR = [
  StatusForAuthorEnum.done,
  StatusForAuthorEnum.canceled,
];

export const CalendarEvent = ({
  event,
  isWeekView,
  tab,
}: EventProps & { isWeekView: boolean; tab: TasksTabEnum }) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const { set } = useQueryParams();
  const { t } = useTranslation("tasks");

  const isOverdue = !!event.resource.delayed;
  const isCompleted =
    tab === TasksTabEnum.incoming
      ? COMPLETED_TYPES_EXECUTOR.includes(event.resource.status)
      : COMPLETED_TYPES_AUTHOR.includes(event.resource.status);

  const handleClick = () => {
    dispatch(openDrawer(DrawerVariantsEnum.taskPreview));
    set("selectedTaskId", event.resource.id);
  };

  const { backgroundColor } = getTaskStatusChipConfig(
    event.resource.status,
    palette,
    t,
    false,
    tab === TasksTabEnum.outgoing
  );

  const Content = useMemo(() => {
    if (isWeekView) {
      return (
        <>
          <Stack width={isOverdue ? "90%" : "100%"} px={2} py="5px">
            <Stack flexDirection="row" alignItems="center">
              <Stack
                sx={{
                  backgroundColor: palette.primary.contrastText,
                  my: "2px",
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="body3"
                  fontWeight={700}
                  sx={{
                    color:
                      backgroundColor === "#E4E6E8"
                        ? palette.text.disabled
                        : backgroundColor,
                    px: 1,
                  }}
                >
                  {t(`types_short.${event.resource.typeOfTheTask}`)}
                </Typography>
              </Stack>

              <Typography
                variant="body3"
                fontWeight={600}
                sx={{ padding: "2px 4px" }}
              >
                № {event.resource.id}
              </Typography>

              <Chip
                variant="outlined"
                label={t(`statuses.${event.resource.status}`)}
                sx={{
                  height: 16,
                  justifyContent: "flex-start",
                  backgroundColor: "transparent",
                  borderColor: "inherit",
                  fontSize: 8,
                  fontWeight: 700,
                  color:
                    backgroundColor === "#E4E6E8"
                      ? palette.text.disabled
                      : palette.primary.contrastText,
                }}
              />
            </Stack>

            {tab === TasksTabEnum.incoming ? (
              <Typography component="p" variant="body4" fontWeight={700}>
                {t("author")}: {event.resource.author || ""}
              </Typography>
            ) : (
              <Typography component="p" variant="body4" fontWeight={700}>
                {event.resource.executor}
              </Typography>
            )}

            <Typography component="p" variant="body4" className="ellipsis">
              {event.resource.text}
            </Typography>
          </Stack>

          {isOverdue && (
            <Stack
              justifyContent="center"
              alignItems="center"
              bgcolor={isCompleted ? "#E4E6E8" : palette.error.main}
              px="1px"
              sx={{
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <Icon
                name="AlarmClock"
                color={
                  isCompleted
                    ? palette.text.secondary
                    : palette.primary.contrastText
                }
              />
            </Stack>
          )}
        </>
      );
    } else {
      return (
        <Stack flexDirection="row" width="100%">
          <Stack flexDirection="row" alignItems="center" width="100%">
            <Stack
              sx={{
                backgroundColor: palette.primary.contrastText,
                margin: "2px",
                ml: 2,
                borderRadius: 1,
              }}
            >
              <Typography
                variant="body3"
                fontWeight={700}
                sx={{
                  color:
                    backgroundColor === "#E4E6E8"
                      ? palette.text.disabled
                      : backgroundColor,
                  px: 1,
                }}
              >
                {t(`types_short.${event.resource.typeOfTheTask}`)}
              </Typography>
            </Stack>

            <Typography
              variant="body3"
              fontWeight={600}
              sx={{ padding: "2px 4px" }}
            >
              № {event.resource.id}
            </Typography>

            {/*<Chip*/}
            {/*  variant="outlined"*/}
            {/*  label={t(`statuses.${event.resource.status}`)}*/}
            {/*  sx={{*/}
            {/*    height: 16,*/}
            {/*    justifyContent: "flex-start",*/}
            {/*    backgroundColor: "transparent",*/}
            {/*    borderColor: "inherit",*/}
            {/*    fontSize: 8,*/}
            {/*    fontWeight: 700,*/}
            {/*    color:*/}
            {/*      backgroundColor === "#E4E6E8"*/}
            {/*        ? palette.text.disabled*/}
            {/*        : palette.primary.contrastText,*/}
            {/*  }}*/}
            {/*/>*/}
          </Stack>

          {isOverdue && (
            <Stack
              justifyContent="center"
              bgcolor={isCompleted ? "#E4E6E8" : palette.error.main}
              sx={{
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <Icon
                name="AlarmClock"
                size={20}
                color={
                  isCompleted
                    ? palette.text.secondary
                    : palette.primary.contrastText
                }
              />
            </Stack>
          )}
        </Stack>
      );
    }
  }, [
    isWeekView,
    isOverdue,
    palette.primary.contrastText,
    palette.text.disabled,
    palette.text.secondary,
    palette.error.main,
    backgroundColor,
    t,
    event.resource.typeOfTheTask,
    event.resource.id,
    event.resource.status,
    event.resource.author,
    event.resource.executor,
    event.resource.text,
    tab,
    isCompleted,
  ]);

  return (
    <Stack
      position="relative"
      flexDirection="row"
      justifyContent="space-between"
      onClick={handleClick}
    >
      {Content}
    </Stack>
  );
};
