import { TFunction } from "i18next";

import { GridColDef } from "@mui/x-data-grid-pro";
import { COLUMN_SIZE } from "@sbm/fe-utils";

export const getAttachLinkTableColumns = (t: TFunction): GridColDef[] => [
  {
    field: "sequenceNumber",
    headerName: t("sequence.number"),
    sortable: false,
    filterable: false,
    width: 80,
  },
  {
    field: "longNameOfTheDocument",
    headerName: t("long_name"),
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: "context",
    headerName: t("context"),
    sortable: false,
    filterable: false,
    minWidth: COLUMN_SIZE * 4,
  },
  {
    field: "createdAt",
    headerName: t("document_date"),
    sortable: false,
    filterable: false,
    width: 140,
  },
];
