import React from "react";

import { Grid, useTheme } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import { TableProps } from "../../types";
import { TableFilters } from "../TableFilters";
import {
  ContentWrapper,
  GridToolbarWrapper,
  TableFiltersWrapper,
} from "./styles";

export const CustomToolbar: React.FC<
  Pick<
    TableProps,
    | "filterableFields"
    | "onSearchOptionChange"
    | "onFilterChange"
    | "filteringButtons"
  >
> = ({
  onSearchOptionChange,
  onFilterChange,
  filterableFields,
  filteringButtons,
}) => {
  const theme = useTheme();

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: filteringButtons ? "initial" : "space-between",
        py: 4,
      }}
    >
      <ContentWrapper>
        <Grid container>
          <Grid item xs={9}>
            <TableFiltersWrapper>
              {filteringButtons}

              <TableFilters
                filterableFields={filterableFields}
                onSearchOptionChange={onSearchOptionChange}
                onFilterChange={onFilterChange}
              />
            </TableFiltersWrapper>
          </Grid>
          <Grid item xs={3}>
            <GridToolbarWrapper>
              <GridToolbarColumnsButton
                sx={{
                  color: theme.palette.secondary.main,
                }}
              />
              <GridToolbarExport
                printOptions={{
                  hideToolbar: true,
                  hideFooter: true,
                  allColumns: true,
                }}
                sx={{ color: theme.palette.secondary.main }}
              />
            </GridToolbarWrapper>
          </Grid>
        </Grid>
      </ContentWrapper>
    </GridToolbarContainer>
  );
};
