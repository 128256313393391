import React, { useEffect } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "app";

import {
  Autocomplete,
  Chip,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  CreateTaskDefaultValuesPerUser,
  ICreateTask,
  TRegistration,
} from "@types";

import { CardWrapper, Wrapper } from "../styles";

interface Props {
  form: UseFormReturn<ICreateTask>;
  registration?: TRegistration | undefined;
}

export const AdditionalInformation = ({
  form: { control, register, setValue, getValues },
  registration,
}: Props) => {
  const { t } = useTranslation("tasks");
  const { contractDirectories } = useAppSelector((state) => state.contracts);

  const { defaultValuesPerUser, executorIds } = useWatch({ control });
  const disabledConstructionComplexes = getValues(
    "defaultValuesPerUser.disabledConstructionComplexes"
  );

  const selected = getValues("defaultValuesPerUser.constructionComplexes");

  const {
    highPriority,
    affiliationConstructionComplex,
    constructionComplexes,
  } = defaultValuesPerUser as CreateTaskDefaultValuesPerUser;

  useEffect(() => {
    if (!affiliationConstructionComplex) {
      setValue("defaultValuesPerUser.constructionComplexes", []);
    }
  }, [affiliationConstructionComplex, setValue]);

  const constructionComplexesIDS = React.useMemo(() => {
    if (!contractDirectories) return [];

    return contractDirectories.constructionComplex.map((i) => ({
      value: i.id,
      title: i?.briefTextConstructionComplexName,
    }));
  }, [contractDirectories]);

  const getDefaultValues = () =>
    constructionComplexesIDS.filter((complex) =>
      constructionComplexes?.includes(complex.value)
    ) || [];

  return (
    <CardWrapper>
      <Typography variant="h9_semiBold" color="text.disabled">
        {t("additional_information")}
      </Typography>
      <Wrapper>
        <FormControlLabel
          label={t("higher_priority")}
          sx={{
            opacity: registration?.priority ? 0.5 : 1,
          }}
          control={
            <Switch
              color="secondary"
              {...register("defaultValuesPerUser.highPriority")}
              onChange={(e, checked) => {
                if (registration?.priority) {
                  return;
                }
                setValue("defaultValuesPerUser.highPriority", checked);
                const newValue = e.target.checked;
                setValue("defaultValuesPerUser.highPriority", newValue);

                executorIds?.forEach((_executor, index) => {
                  setValue(`executorIds.${index}.highPriority`, newValue);
                });
              }}
              checked={highPriority}
            />
          }
        />
        <FormControlLabel
          label={t("affiliation_to_construction_complex")}
          sx={{ opacity: disabledConstructionComplexes.length ? 0.5 : 1 }}
          control={
            <Switch
              color="secondary"
              {...register(
                "defaultValuesPerUser.affiliationConstructionComplex"
              )}
              onChange={(e, checked) => {
                if (disabledConstructionComplexes.length) {
                  return;
                }
                setValue(
                  "defaultValuesPerUser.affiliationConstructionComplex",
                  checked
                );
                executorIds?.forEach((_executor, index) => {
                  setValue(
                    `executorIds.${index}.affiliationConstructionComplex`,
                    checked
                  );
                });

                if (!checked) {
                  // If the switch is turned off, clear the construction complexes
                  setValue("defaultValuesPerUser.constructionComplexes", []);
                }
              }}
              checked={
                !!disabledConstructionComplexes.length ||
                affiliationConstructionComplex
              }
            />
          }
        />
      </Wrapper>
      {affiliationConstructionComplex ? (
        <Autocomplete
          multiple
          options={constructionComplexesIDS}
          value={constructionComplexesIDS.filter((value) =>
            selected.includes(value.value)
          )}
          onChange={(event, newValue) => {
            const idsToSet = [
              ...disabledConstructionComplexes,
              ...newValue
                .map((i) => i.value)
                .filter((v) => !disabledConstructionComplexes.includes(v)),
            ];
            setValue("defaultValuesPerUser.constructionComplexes", idsToSet, {
              shouldDirty: true,
            });
            executorIds?.forEach((_executor, index) => {
              setValue(`executorIds.${index}.constructionComplexes`, idsToSet);
            });
          }}
          getOptionLabel={(option) => option.title}
          defaultValue={getDefaultValues()}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              color="secondary"
              size="medium"
              label={t("affiliation_construction_complex")}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              return (
                <div key={index}>
                  <Chip
                    label={option.title}
                    {...getTagProps({ index })}
                    disabled={disabledConstructionComplexes.includes(
                      option.value
                    )}
                  />
                </div>
              );
            })
          }
        />
      ) : null}
    </CardWrapper>
  );
};
